/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CheckIn } from '../models/check-in';
import { CheckInAccess } from '../access/check-in-access.service';


/**
 * Class providing management methods for check-ins.
 */
@Injectable({
  providedIn: 'root'
})
export class CheckInManager {

  /**
   * The default constructor.
   */
  constructor(
    private checkInAccess: CheckInAccess
  ) {
  }

  /**
   * Add new CheckIn to cloud.
   *
   * @param checkIn the CheckIn object to add
   * @return the id of the new CheckIn
   */
  public async addCheckIn(checkIn: CheckIn): Promise<string> {
    return this.checkInAccess.addCheckIn(checkIn);
  }

  /**
   * Returns all CheckIns of a location.
   *
   * @param locationId the ID of the location
   * @returns the found CheckIns, otherwise empty list
   */
  public getAllCheckIns(locationId: string): Observable<CheckIn[]> {
    return this.checkInAccess.getAllCheckIns(locationId);
  }

  /**
   * Returns the CheckIn specified by the ID.
   *
   * @param checkInId the CheckIn ID
   * @returns the found CheckIn, otherwise undefined
   */
  public getCheckIn(checkInId: string): Observable<CheckIn> {
    return this.checkInAccess.getCheckIn(checkInId);
  }

  /**
   * Removes CheckIns.
   *
   * @param checkInIds the IDs of the CheckIns to remove
   */
  public async removeCheckIns(checkInIds: string[]): Promise<void> {
    return this.checkInAccess.removeCheckIns(checkInIds);
  }

  /**
   * Changes status of CheckIns.
   *
   * @param checkInIds the IDs of the CheckIns to change
   * @param status possible values are 'CHECKED_IN', 'CHECKED_OUT'
   */
  public async setStatusOfCheckIns(checkInIds: string[], status: string): Promise<void> {
    return this.checkInAccess.setStatusOfCheckIns(checkInIds, status);
  }

  /**
   * Sets status of a CheckIn object to CHECKED_OUT.
   *
   * @param checkInId the ID of the checkIn object to check-out for
   */
  public async checkOutGuest(checkInId: string): Promise<void> {
    return this.checkInAccess.checkOutGuest(checkInId);
  }
}
