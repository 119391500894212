/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {DomController} from '@ionic/angular';


@Component({
  selector: 'app-shrinking-segment-header',
  templateUrl: './shrinking-segment-header.component.html',
  styleUrls: ['./shrinking-segment-header.component.scss'],
})
export class ShrinkingSegmentHeaderComponent implements OnInit, AfterViewInit {

  @Input() scrollArea: any;
  @Input() headerHeight: number;
  @Input() headerImage: string;

  private newHeaderHeight: number;
  private newHeaderOpacity: number;

  constructor(
    public element: ElementRef,
    public renderer: Renderer2,
    private domCtrl: DomController
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.element.nativeElement, 'height', this.headerHeight + 'px');
    this.renderer.setStyle(this.element.nativeElement, 'background-image', 'url(' + this.headerImage + ')');
    this.renderer.setStyle(this.element.nativeElement, 'background-repeat', 'no-repeat');
    this.renderer.setStyle(this.element.nativeElement, 'background-position', 'center center');
    this.renderer.setStyle(this.element.nativeElement, 'background-size', 'cover');

    this.scrollArea.ionScroll.subscribe((ev: CustomEvent) => {
      this.resizeHeader(ev);
    });
  }

  resizeHeader(ev: CustomEvent) {
    this.domCtrl.write(() => {
      this.newHeaderHeight = this.headerHeight - ev.detail.scrollTop;

      if (this.newHeaderHeight < 0) {
        this.newHeaderHeight = 0;
      }
      this.newHeaderOpacity = this.newHeaderHeight / this.headerHeight;

      this.renderer.setStyle(this.element.nativeElement, 'height', this.newHeaderHeight + 'px');
      this.renderer.setStyle(this.element.nativeElement, 'opacity', this.newHeaderOpacity);
    });
  }
}
