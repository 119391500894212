/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';

import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TextAvatarModule } from '../../components/text-avatar';

import { environment } from '../../environments/environment';
import { CloudImageDirective } from './directive/cloud-image.directive';
import { ShrinkingSegmentHeaderComponent } from './component/shrinking-segment-header/shrinking-segment-header.component';
import { TextEditComponent } from './modal/text-edit/text-edit.component';
import { AddressEditComponent } from './modal/address-edit/address-edit.component';
import { LocationPricePipe } from './pipe/location-price.pipe';
import { TranslationPipe } from './pipe/translation.pipe';
import { DurationPipe } from './pipe/duration.pipe';
import { MomentDatePipe } from './pipe/moment-date.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';
import { AppMissingTranslationHandler } from '../app-missing-translation-handler';


// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  // use timestamp to prevent browser from caching
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + environment.app.version);
}

@NgModule({
  declarations: [
    CloudImageDirective,
    ShrinkingSegmentHeaderComponent,
    TextEditComponent,
    AddressEditComponent,
    LocationPricePipe,
    TranslationPipe,
    DurationPipe,
    MomentDatePipe,
    TruncatePipe
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingTranslationHandler
      },
      useDefaultLang: false
    }),
    HttpClientModule
  ],
  exports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ShrinkingSegmentHeaderComponent,
    TextEditComponent,
    AddressEditComponent,
    CloudImageDirective,
    LocationPricePipe,
    TranslationPipe,
    DurationPipe,
    MomentDatePipe,
    TruncatePipe,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatIconModule,
    MatBadgeModule,
    TextAvatarModule
  ],
  entryComponents: [
    TextEditComponent
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe
  ]
})
export class SharedModule {
}
