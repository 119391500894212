/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { first, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';

import { environment } from '../../../environments/environment';
import { Customer, LocationInfo } from '../models/customer';
import { Address } from '../models/address';
import { User } from '../models/user';
import { CustomerManager } from './customer-manager.service';
import { UserManager } from './user-manager.service';
import { Session } from '../businessobject/session.service';

import * as firebase from 'firebase/app';
import AuthCredential = firebase.auth.AuthCredential;
import UserCredential = firebase.auth.UserCredential;
import ConfirmationResult = firebase.auth.ConfirmationResult;


/**
 * Class providing management methods for authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthManager {

  private currentFirebaseUser: firebase.User | null;

  /**
   * The default constructor.
   */
  constructor(
    private afAuth: AngularFireAuth,
    private session: Session,
    private customerManager: CustomerManager,
    private userManager: UserManager
  ) {
  }

  /**
   * Starts checking the logged-in customer.
   *
   * @returns the logged-in user
   */
  public startCheckingLoggedInCustomer(): Observable<void> {
    return this.afAuth.authState.pipe(
      switchMap((firebaseUser: firebase.User | null) => {
        this.currentFirebaseUser = firebaseUser;

        return (!firebaseUser || (environment.auth.useEmailVerification && !firebaseUser.emailVerified))
          ? of(undefined)
          : this.customerManager.getCustomerByFirebaseUser(firebaseUser.uid);
      }),
      map((customer: Customer | undefined) => {
        this.session.setCurrentCustomer(customer);
        this.session.setReady();
      })
    );
  }

  public isAnonymous(): boolean {
    return this.currentFirebaseUser ? this.currentFirebaseUser.isAnonymous : false;
  }

  public async registerCustomerByEmail(params: {
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    verificationCode?: string,
    phoneNumber?: string,
    address?: Address,
    floor?: string,
    marketingConsent: boolean,
    visitedLocationInfos?: LocationInfo[]
  }): Promise<[string, Customer | undefined]> {
    let customer: Customer | undefined;

    let credential: UserCredential;
    try {
      credential = await this.afAuth.createUserWithEmailAndPassword(params.email, params.password);
    } catch (e) {
      throw new Error('AUTH.ERROR_CREATING_FIREBASE_USER');
    }

    customer = await this.customerManager.getCustomerByEmail(params.email).pipe(first()).toPromise();
    if (!customer) {
      customer = new Customer();
      customer.verified = true;
    }

    customer.firebaseUserId = credential.user.uid;
    customer.firstname = params.firstname;
    customer.lastname = params.lastname;
    customer.email = credential.user.email;
    customer.photoUrl = credential.user.photoURL ? credential.user.photoURL : null;
    if (customer.verified !== true && customer.verificationCode === params.verificationCode) {
      customer.verified = true;
    }
    customer.phoneNumber = params.phoneNumber ? params.phoneNumber.trim() : null;
    customer.address = params.address ? params.address : null;
    customer.floor = params.floor ? params.floor : null;
    customer.marketingConsent = params.marketingConsent;
    if (params.visitedLocationInfos) {
      customer.visitedLocationInfos = params.visitedLocationInfos;
    }

    customer.id = await this.customerManager.addCustomer(customer);

    if (customer.verified !== true) {
      await this.signOut();
      await this.customerManager.sendVerificationCode(customer.id);
      return ['AUTH.VERIFICATION_CODE_WRONG', customer];
    }

    if (environment.auth.useEmailVerification) {
      await credential.user.sendEmailVerification();
    }

    return ['OK', customer];
  }

  public async requestPassword(customerEmail: string): Promise<void> {
    try {
      await this.afAuth.sendPasswordResetEmail(customerEmail);
    } catch (e) {
      throw new Error('AUTH.ERROR_PASSWORD_RESET');
    }
  }

  public async doEmailLogin(customerEmail: string, customerPassword: string): Promise<Customer> {
    let credential: UserCredential;
    try {
      credential = await this.afAuth.signInWithEmailAndPassword(customerEmail, customerPassword);
    } catch (e) {
      return Promise.reject(new Error('AUTH.INVALID_CREDENTIALS'));
    }

    if (environment.auth.useEmailVerification && !credential.user.emailVerified) {
      await this.afAuth.signOut();
      return Promise.reject(new Error('AUTH.VERIFY_EMAIL'));
    }

    let customer: Customer | undefined = await this.customerManager.getCustomerByFirebaseUser(credential.user.uid).pipe(first()).toPromise();

    // in case there is already a user but no customer with the same email address
    if (customer === undefined) {
      const user: User | undefined = await this.userManager.getUser(credential.user.uid).pipe(first()).toPromise();
      if (user !== undefined) {
        customer = new Customer();
        customer.firebaseUserId = credential.user.uid;
        customer.firstname = user.firstname;
        customer.lastname = user.lastname;
        customer.email = credential.user.email;
        customer.photoUrl = credential.user.photoURL;
        customer.verified = true;

        customer.id = await this.customerManager.addCustomer(customer);
      } else {
        await this.afAuth.signOut();
        return Promise.reject(new Error('AUTH.MIGRATE_FROM_USER'));
      }
    } else if (customer.verified !== true) {
      await this.afAuth.signOut();
      await this.customerManager.sendVerificationCode(customer.id);
      return Promise.reject(new Error('AUTH.VERIFY_EMAIL'));
    }

    return customer;
  }

  public async doGuestLogin(firstname: string, lastname: string): Promise<Customer> {
    const credential: UserCredential = await this.afAuth.signInAnonymously();

    const customer: Customer = new Customer();
    customer.firebaseUserId = credential.user.uid;
    customer.firstname = firstname;
    customer.lastname = lastname;
    customer.email = credential.user.email;
    customer.photoUrl = credential.user.photoURL;

    customer.id = await this.customerManager.addCustomer(customer);

    return customer;
  }

  public async upgradeCustomer(customerEmail: string, customerPassword: string): Promise<Customer> {
    const authCredential: AuthCredential = firebase.auth.EmailAuthProvider.credential(customerEmail, customerPassword);
    const currentUser: firebase.User = await this.afAuth.currentUser;
    const credential: UserCredential = await currentUser.linkWithCredential(authCredential);

    const customer: Customer = await this.customerManager.getCustomerByFirebaseUser(currentUser.uid).pipe(first()).toPromise();
    customer.email = credential.user.email;

    await this.customerManager.updateCustomer(customer);

    return customer;
  }

  public async setPassword(email: string, oldPassword: string, newPassword: string): Promise<void> {
    const currentUser: firebase.User = await this.afAuth.currentUser;
    const credential: AuthCredential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);

    await currentUser.reauthenticateWithCredential(credential).catch(_ => { throw new Error('AUTH.INVALID_CREDENTIALS'); });
    await currentUser.updatePassword(newPassword).catch(_ => { throw new Error('AUTH.INVALID_NEW_PASSWORD'); });
  }

  public async requestLoginCode(phoneNumber: string): Promise<void> {
    try {
//      const appVerifier = this.windowRef.recaptchaVerifier;
//      const confirmationResult: ConfirmationResult = await this.afAuth.auth.signInWithPhoneNumber(phoneNumber, appVerifier);
    } catch (error) {
      console.log(error);
    }
  }

  public async verifyLoginCode(confirmationResult: ConfirmationResult, verificationCode: string, firstname: string, lastname: string): Promise<Customer> {
    const credential: UserCredential = await confirmationResult.confirm(verificationCode);

    const customer: Customer = new Customer();
    customer.firebaseUserId = credential.user.uid;
    customer.firstname = firstname;
    customer.lastname = lastname;
    customer.email = credential.user.email;
    customer.photoUrl = credential.user.photoURL;
    customer.verified = true;

    customer.id = await this.customerManager.addCustomer(customer);

    return customer;
  }

  public async signOut() {
    await this.afAuth.signOut();
  }
}
