/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Customer } from '../models/customer';
import { CustomerAccess } from '../access/customer-access.service';


/**
 * Class providing management methods for customers.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomerManager {

  /**
   * The default constructor.
   */
  constructor(
    private customerAccess: CustomerAccess
  ) {
  }

  /**
   * Add new Customer to cloud.
   *
   * @param customer the Customer object to add
   * @return the id of the new Customer
   */
  public async addCustomer(customer: Customer): Promise<string> {
    return this.customerAccess.addCustomer(customer);
  }

  /**
   * Update a Customer.
   *
   * @param customer the Customer object to update
   * @return the id of the Customer
   */
  public async updateCustomer(customer: Customer): Promise<string> {
    return this.customerAccess.updateCustomer(customer);
  }

  /**
   * Returns all customers.
   *
   * @returns the found customers, otherwise empty list
   */
  public getAllCustomers(): Observable<Customer[]> {
    return this.customerAccess.getAllCustomers();
  }

  /**
   * Returns the Customer specified by the ID.
   *
   * @param customerId the Customer ID
   * @returns the found Customer, otherwise undefined
   */
  public getCustomer(customerId: string): Observable<Customer | undefined> {
    return this.customerAccess.getCustomer(customerId);
  }

  /**
   * Returns the Customer specified by the firebase user ID.
   *
   * @param firebaseUserId the ID of the firebase user associated with this customer
   * @returns the found Customer, otherwise undefined
   */
  public getCustomerByFirebaseUser(firebaseUserId: string): Observable<Customer | undefined> {
    return this.customerAccess.getCustomerByFirebaseUser(firebaseUserId);
  }

  /**
   * Returns the Customer specified by the email address.
   *
   * @param email the customer's email address
   * @returns the found Customer, otherwise undefined
   */
  public getCustomerByEmail(email: string): Observable<Customer | undefined> {
    return this.customerAccess.getCustomerByEmail(email);
  }

  /**
   * Returns the Customer specified by the reference ID.
   *
   * @param referenceId the reference ID
   * @param referenceService the reference service
   * @returns the found Customer, otherwise undefined
   */
  public getCustomerByReference(referenceId: string, referenceService: string): Observable<Customer | undefined> {
    return this.customerAccess.getCustomerByReference(referenceId, referenceService);
  }

  /**
   * Returns the unverified Customer specified by the verification code.
   *
   * @param verificationCode the verification code
   * @returns the found Customer, otherwise undefined
   */
  public getCustomerByVerificationCode(verificationCode: string): Observable<Customer | undefined> {
    return this.customerAccess.getCustomerByVerificationCode(verificationCode);
  }

  /**
   * Removes Customers.
   *
   * @param customerIds the IDs of the Customers to remove
   */
  public async removeCustomers(customerIds: string[]): Promise<void> {
    return this.customerAccess.removeCustomers(customerIds);
  }

  /**
   * Send verification code to Customer.
   *
   * @param customerId the ID of the Customer object to send the verification code to
   */
  public async sendVerificationCode(customerId: string): Promise<void> {
    return this.customerAccess.sendVerificationCode(customerId);
  }
}
