/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';


@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
    private decimalPipe: DecimalPipe
  ) {
  }

  /**
   * Converts minutes to hours if necessary.
   *
   * @param value in minutes
   * @param digitsInfo Decimal representation options, specified by a string
   * in the following format:<br>
   * <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   * Default is `1`.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   * Default is `2`.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * Default is `2`.
   * If not provided, the number will be formatted with the proper amount of digits,
   * depending on what the [ISO 4217](https://en.wikipedia.org/wiki/ISO_4217) specifies.
   * For example, the Canadian dollar has 2 digits, whereas the Chilean peso has none.
   * @param locale A locale code for the locale format rules to use.
   * When not supplied, uses the value of `LOCALE_ID`, which is `en-US` by default.
   * See [Setting your app locale](guide/i18n#setting-up-the-locale-of-your-app).
   * @returns value in hours
   */
  transform(value: number, digitsInfo?: string, locale?: string): string {
    if (value > 0 && value < 60) {
      return this.translateService.instant('PIPE_DURATION.IN_MINUTES', {time: value});
    } else {
      const inHours: string = this.decimalPipe.transform(value / 60, digitsInfo, locale);
      return this.translateService.instant('PIPE_DURATION.IN_HOURS', {time: inHours});
    }
  }
}
