/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonSearchbar, ModalController, ToastController } from '@ionic/angular';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import AutocompletePrediction = google.maps.places.AutocompletePrediction;

import { Address } from '../../models/address';
import { AddressManager } from '../../manager/address-manager.service';
import { AddressUtils } from '../../utils/address-utils';


@Component({
  selector: 'app-text-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss'],
})
export class AddressEditComponent implements OnInit {

  @Input() header?: string;
  @Input() subHeader?: string;
  @Input() message?: string;
  @Input() inputPlaceholder?: string;
  @Input() initAddress?: Address;
  @Input() addressTypes?: string[];  // 'establishment' / 'address' / 'geocode'
  @Input() originLat?: number;
  @Input() originLng?: number;

  @ViewChild('inputView', {static: false}) inputView: IonSearchbar;

  public searchControl: FormControl;

  public input: string | null;
  public isSelectedPlace = false;
  public predictions: AutocompletePrediction[] = [];
  public response: { placeId?: string, address?: Address } = {};

  constructor(
    protected modalController: ModalController,
    protected alertController: AlertController,
    protected toastController: ToastController,
    protected translateService: TranslateService,
    protected addressManager: AddressManager
  ) {
    this.searchControl = new FormControl();
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.initAddress) {
        this.inputView.value = AddressUtils.getFormattedAddress(this.initAddress);
      }
      this.inputView.setFocus().then();
    }, 400);

    this.searchControl.valueChanges.pipe(debounceTime(0)).subscribe(async (search: string) => {
      try {
        if (search !== null && search.length > 0) {
          this.predictions = await this.addressManager.findAddresses(search, this.addressTypes, this.originLat, this.originLng);
        } else {
          this.predictions = [];
        }
      } catch (error) {
        this.predictions = [];
        if (error === 'ZERO_RESULTS') {
          this.showToast(this.translateService.instant('SELECT_ADDRESS.FIND_ADDRESSES_EMPTY'));
        } else {
          console.log(error);
          this.showToast(this.translateService.instant('SELECT_ADDRESS.FIND_ADDRESSES_ERROR'));
        }
      }
    });
  }

  public async selectPlace(prediction: AutocompletePrediction): Promise<void> {
    this.isSelectedPlace = true;

    if (prediction.types.includes('street_address') || prediction.types.includes('plus_code')
      || prediction.types.includes('premise') || prediction.types.includes('subpremise')
      || prediction.types.includes('establishment')) {
      try {
        this.response.placeId = prediction.place_id;
        this.response.address = await this.addressManager.getAddress(prediction.place_id);

        if (this.response.address
          && this.response.address.addressLine1 && this.response.address.locality && this.response.address.postalCode && this.response.address.country) {
          this.closeModal(false, 'ok');
        }
      } catch (e) {
        console.log(e);
        this.response.placeId = undefined;
        this.response.address = undefined;
        this.showToast(this.translateService.instant('SELECT_ADDRESS.GET_ADDRESS_ERROR'));
      }
    } else {
      this.inputView.value = prediction.description;
      this.inputView.setFocus().then();
    }
  }

  public async closeModal(cancel: boolean, role: string): Promise<void> {
    await this.modalController.dismiss(cancel ? undefined : this.response, role);
  }

  private async showToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration: 4000
    });
    toast.present().then();
  }

  /**
   * Shows a custom error message.
   * @param title Title of custom error-message
   * @param text Text of custom error-message
   */
  public async showError(title: string, text: string): Promise<void> {
    const alert = await this.alertController.create({
      header: title,
      message: text,
      buttons: [this.translateService.instant('APP.OK')]
    });

    await alert.present();
  }
}
