/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appId: 'happz_dev',
  production: false,
  app: {
    version: '2.4.8-1',
    rootUrl: 'http://localhost:8100',
    defaultLang: 'de',
    defaultTimezone: 'Europe/Berlin'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCvMGkxeUoZgaZUnJR6aATPIBqh3Xtfuus',
    authDomain: 'happz-dev.firebaseapp.com',
    databaseURL: 'https://happz-dev.firebaseio.com',
    projectId: 'happz-dev',
    storageBucket: 'happz-dev.appspot.com',
    messagingSenderId: '123751527969',
    appId: '1:123751527969:web:aa1d463a0d6a3c26'
  },
  auth: {
    useEmailVerification: false
  },
  location: {
    link: 'http://localhost:8100/l/{{locationLinkName}}',
    linkWithItem: 'http://localhost:8100/l/{{locationLinkName}}?itemId={{itemId}}'
  },
  cloudinaryConfig: {
    cloudName: 'dhmeodtyu',
    imageRefName: 'cloudinary'
  },
  stripePaymentConfig: {
    apiPublicKey: 'pk_test_QhP2AHSvSmpiJS1G6A1FY3Wh002fNVXDai',
    apiUsername: 'stripe-dev',
    apiPassword: 'phlDJTT0OJEXWHn8S9ig',
    createCustomerUrl: 'https://europe-west3-happz-dev.cloudfunctions.net/stripeCreateCustomerEur',
    updateCustomerUrl: 'https://europe-west3-happz-dev.cloudfunctions.net/stripeUpdateCustomerEur',
    createPaymentSessionUrl: 'https://europe-west3-happz-dev.cloudfunctions.net/stripeCreatePaymentSessionEur',
    createOrderSessionUrl: 'https://europe-west3-happz-dev.cloudfunctions.net/stripeCreateOrderSessionEur',
    capturePaymentUrl: 'https://europe-west3-happz-dev.cloudfunctions.net/stripe/v1/CapturePayment'
  },
  paypalPaymentConfig: {
    clientId: 'AeLLOsqsVT0op9Z8nx4W3TJtrrWiAo69Fzimhigmmc4j7UvJEoCdCuthP0Nk5CCkvU032GdRppNMfWvd',
    apiUsername: 'paypal-dev',
    apiPassword: 'NQhPameTJoJ5AFgZOC6K',
    createOrderSessionUrl: 'https://us-central1-happz-dev.cloudfunctions.net/paypal/v1/CreateOrderSession',
    capturePaymentUrl: 'https://us-central1-happz-dev.cloudfunctions.net/paypal/v1/CapturePayment'
  },
  firebaseEndpoint: {
    apiKey: 'CvhFIxtlcXx1uydZIXyR',
    checkOutGuest: 'https://europe-west3-happz-dev.cloudfunctions.net/checkOutGuest',
    sendVerificationCode: 'https://europe-west3-happz-dev.cloudfunctions.net/sendVerificationCode',
    getReservationByCancellationCode: 'https://europe-west3-happz-dev.cloudfunctions.net/api/reservation/cancelcode'
  },
  accountBusinessTypeUi: {
    RESTAURANT: {
      id: 'RESTAURANT', langKeyPrefix: '', showZeroMoney: true, showLocationAddress: true,
      imageSize: 'M', skipAddressPrompt: false, hidePreferredTime: false
    },
    SHOP: {
      id: 'SHOP', langKeyPrefix: 'SHOP.', showZeroMoney: true, showLocationAddress: false,
      imageSize: 'L', skipAddressPrompt: true, hidePreferredTime: false
    },
    ACCOMMODATION: {
      id: 'ACCOMMODATION', langKeyPrefix: 'ACCOMMODATION.', showZeroMoney: true, showLocationAddress: false,
      imageSize: 'M', skipAddressPrompt: false, hidePreferredTime: false
    },
    ACCOMMODATION_MANAGED: {
      id: 'ACCOMMODATION_MANAGED', langKeyPrefix: 'MANAGED.ACCOMMODATION.', showZeroMoney: false, showLocationAddress: false,
      imageSize: 'M', skipAddressPrompt: false, hidePreferredTime: true
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
