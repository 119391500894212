/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { concat, interval, Subject } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';

import { SwUpdate } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';

import { Customer } from './shared/models/customer';
import { AuthManager } from './shared/manager/auth-manager.service';
import { AnalyticsManager } from './shared/manager/analytics-manager.service';
import { Session } from './shared/businessobject/session.service';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private stop$: Subject<boolean> = new Subject<boolean>();
  public currentCustomer?: Customer;
  public selectedLanguageCode: string;

  public appVersion: string = environment.app.version;
  private forceLogoutAlertShown = false;

  public cookieConsent = false;

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private modalController: ModalController,
    public translateService: TranslateService,
    private alertController: AlertController,
    private authManager: AuthManager,
    private session: Session,
    private analyticsManager: AnalyticsManager
  ) {
    this.initializeApp();

    registerLocaleData(localeDe);
    translateService.setDefaultLang(environment.app.defaultLang);
    translateService.addLangs(['de', 'en']);
    translateService.use(environment.app.defaultLang);

    this.session.getValue(this.session.PREFERRED_LANGUAGE).pipe(
      takeUntil(this.stop$)
    ).subscribe((language: string) => {
      translateService.use(translateService.getLangs().includes(language) ? language : environment.app.defaultLang);
    });

    const preferredLanguage: string = navigator.language.substr(0, 2);
    this.selectedLanguageCode = translateService.getLangs().includes(preferredLanguage) ? preferredLanguage : environment.app.defaultLang;
    session.setValue(session.PREFERRED_LANGUAGE, preferredLanguage).then();

    authManager.startCheckingLoggedInCustomer().pipe(
      switchMap(() => {
        return session.getCurrentCustomer();
      }),
      takeUntil(this.stop$)
    ).subscribe((customer: Customer) => {
      this.currentCustomer = customer;
    });

    session.getValue(session.COOKIE_CONSENT).pipe(
      takeUntil(this.stop$)
    ).subscribe((value: string | null) => {
      this.cookieConsent = value === 'true';
    });
  }

  private initializeApp(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.statusBar.backgroundColorByHexString('#ff7e79');
        this.statusBar.styleLightContent();
        this.splashScreen.hide();
      }
    });
  }

  public ngOnInit(): void {
    if (this.swUpdate.isEnabled) {  // check for browsers not supporting ServiceWorkers
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const waitingPeriod$ = interval(60 * 1000);
      concat(appIsStable$, waitingPeriod$).pipe(
        takeUntil(this.stop$)
      ).subscribe(() => {
        this.swUpdate.checkForUpdate();
      });

      this.swUpdate.available.pipe(
        takeUntil(this.stop$)
      ).subscribe(async () => {
        throw new Error('REQUEST_RELOAD');
      });
    }
  }

  public ngOnDestroy(): void {
    this.stop$.next(true);
    this.stop$.unsubscribe();
  }

  public async setCookieConsent(): Promise<void> {
    await this.session.setValue(this.session.COOKIE_CONSENT, 'true', true);
  }

  /**
   * Sets the language to use.
   *
   * @param $event the ion-select CustomEvent
   */
  public useLanguage($event: CustomEvent): void {
    this.selectedLanguageCode = $event.detail.value;
    this.session.setValue(this.session.PREFERRED_LANGUAGE, $event.detail.value).then();
  }

  /**
   * Logs out the customer.
   */
  public async logout(): Promise<void> {
    const alert = await this.alertController.create({
      message: this.translateService.instant('APP.CONFIRM_LOGOUT'),
      buttons: [
        {
          text: this.translateService.instant('APP.CANCEL'),
          role: 'cancel',
          handler: async () => {
            await this.alertController.dismiss();
          }
        }, {
          text: this.translateService.instant('APP.OK'),
          handler: async () => {
            await this.authManager.signOut();
            await this.analyticsManager.logEvent('side_menu', undefined, 'customer', 'logout');
          }
        }
      ]
    });

    await alert.present();
  }

  /**
   * Forces to log out the customer.
   */
  private async forceLogout(): Promise<void> {
    if (this.currentCustomer && !this.forceLogoutAlertShown) {
      this.forceLogoutAlertShown = true;

      const alert = await this.alertController.create({
        message: this.translateService.instant('APP.CONFIRM_FORCE_LOGOUT'),
        buttons: [
          {
            text: this.translateService.instant('APP.OK'),
            handler: async () => {
              await this.authManager.signOut();
              await this.analyticsManager.logEvent('side_menu', undefined, 'customer', 'logout');
            }
          }
        ],
        backdropDismiss: false
      });

      alert.onDidDismiss().then(() => this.forceLogoutAlertShown = false);

      await alert.present();
    }
  }

  public async startCustomer(id: string): Promise<void> {
    // const modal: HTMLIonModalElement = await this.modalController.create({
    //   component: CustomerDetailsComponent,
    //   componentProps: { userId: id },
    //   backdropDismiss: false,
    //   cssClass: 'fullscreen-modal'
    // });
    //
    // return await modal.present();
  }
}
