/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { CatalogItemVariation, ItemVariationLocationOverride} from '../models/catalog-item-variation';
import { Money } from '../models/money';


/**
 * Utility class for location price.
 */
export class LocationPriceUtils {

  /**
   * Returns the price of the CatalogItem specific for a location.
   *
   * @param variation the CatalogItemVariation object holding price information
   * @param locationId the location ID
   * @returns the price specific for a location
   */
  static getPrice(variation: CatalogItemVariation, locationId?: string): Money | null {
    let priceMoney: Money | null = null;

    if (variation) {
      if (variation.priceMoney && variation.priceMoney.amount !== null && !isNaN(variation.priceMoney.amount)) {
        priceMoney = new Money();
        priceMoney.amount = variation.priceMoney.amount;
        priceMoney.currency = variation.priceMoney.currency;
      }

      if (locationId && variation.locationOverrides) {
        const override: ItemVariationLocationOverride | undefined = variation.locationOverrides
          .find((record: ItemVariationLocationOverride) => record.locationId === locationId);
        if (override && override.priceMoney && override.priceMoney.amount !== null && !isNaN(override.priceMoney.amount)) {
          priceMoney = new Money();
          priceMoney.amount = override.priceMoney.amount;
          priceMoney.currency = override.priceMoney.currency;

          // take variation currency as a fallback
          if (!priceMoney.currency && variation.priceMoney && variation.priceMoney.currency) {
            priceMoney.currency = variation.priceMoney.currency;
          }
        }
      }
    }

    return priceMoney;
  }
}
