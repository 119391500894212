/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  constructor(
  ) {
  }

  /**
   * Truncate a text if necessary.
   *
   * @param value text to truncate
   * @param limit the maximum number of characters before truncating
   * @param trail the string to append if truncating
   * @returns truncated value
   */
  transform(value: string, limit?: number, trail: string = '...'): string {
    return limit && value && value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
