/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

import { Location } from '../models/location';
import { Address } from '../models/address';


/**
 * Class providing access methods for the Foursquare API.
 */
@Injectable({
  providedIn: 'root'
})
export class FoursquareLocationAccess {

  /**
   * The default constructor.
   */
  constructor(
    private http: HttpClient,
  ) { }

  /**
   * Returns nearby locations.
   *
   * @param latitude the position's latitude of the user
   * @param longitude the position's longitude of the user
   * @param searchText the search text, optional
   * @param radius the search radius, default 300m, null for unlimited
   * @param limit the maximum number of results, default 30
   * @returns the matching locations, otherwise empty list
   */
  public async findLocationsNearby(latitude: number, longitude: number, searchText?: string,
                                   radius: number | null = 300, limit: number = 30): Promise<Location[]> {
    const locations: Location[] = [];

    const ll = latitude + ',' + longitude;

    let url: string = 'https://api.foursquare.com/v2/venues/search' +
      '?client_id=JZYOJ0IOEWWPQRIWTBUOEVF3AS3ZLP4SBK5OX44O4IEEBMVK' +
      '&client_secret=PJPOCVS1GKLOUVH1BUFKATV5OKS5G32NPYBLL2PY3FA2ZWYR' +
      '&v=20190317' +
      '&limit=' + limit +
      '&ll=' + ll +
      '&categoryId=4d4b7105d754a06374d81259';
    if (radius) {
      url += '&radius=' + radius;
    }
    if (searchText) {
      url += '&query=' + searchText;
    }

    const response: any = await this.http.get(url).pipe(first()).toPromise();

    if (response.hasOwnProperty('response') && response.response.hasOwnProperty('venues')) {
      response.response.venues.forEach((venue: any) => {
        const location: Location = new Location();
        location.refs = [{ name: 'foursquare', ref: venue.id}];
        location.name = venue.hasOwnProperty('name') ? venue.name : null;
        location.businessName = venue.hasOwnProperty('name') ? venue.name : null;
        location.description = venue.hasOwnProperty('description') ? venue.description : null;
        location.type = 'PHYSICAL';
        if (venue.hasOwnProperty('location')) {
          location.address = new Address();
          location.address.addressLine1 = venue.location.hasOwnProperty('address') ? venue.location.address : null;
          location.address.postalCode = venue.location.hasOwnProperty('postalCode') ? venue.location.postalCode : null;
          location.address.country = venue.location.hasOwnProperty('country') ? venue.location.country : null; // cc e.g. DE
          location.address.locality = venue.location.hasOwnProperty('city') ? venue.location.city : null;
          location.address.administrativeDistrictLevel1 = venue.location.hasOwnProperty('state') ? venue.location.state : null;
          location.address.lat = venue.location.hasOwnProperty('lat') ? venue.location.lat : null;
          location.address.lng = venue.location.hasOwnProperty('lng') ? venue.location.lng : null;
          location.countryCode = venue.location.hasOwnProperty('cc') ? venue.location.cc : null;
          location.country = venue.location.hasOwnProperty('country') ? venue.location.country : null;
        }
        if (venue.hasOwnProperty('contact')) {
          location.phoneNumber = venue.contact.hasOwnProperty('phone') ? venue.contact.phone : null;
          location.instagramUrl = venue.contact.hasOwnProperty('instagram') ? venue.contact.instagram : null;
          location.facebookUrl = venue.contact.hasOwnProperty('facebook') ? venue.contact.facebook : null;
          location.twitterUrl = venue.contact.hasOwnProperty('twitter') ? venue.contact.twitter : null;
        }
        location.websiteUrl = venue.hasOwnProperty('url') ? venue.url : null;
        location.cuisine = venue.hasOwnProperty('categories') ? venue.categories[0].shortName : null;
        location.timezone = venue.hasOwnProperty('timeZone') ? venue.timeZone : null;

        locations.push(location);
      });
    }

    return locations;
  }
}
