/*
 * Copyright: Happz UG (haftungsbeschränkt)
 *            Stresemannstr. 25
 *            10963 Berlin
 *            Germany
 *
 * http://www.happz.de/
 *
 * $Date$
 * $Revision$
 * $Author$
 * $HeadURL$
 */
import { Address } from './address';


/**
 * Represents a check-in introduced during the COVID pandemic.
 */
export class CheckIn {

  /** The unique ID of this object. */
  public id: string;
  /** The account's unique ID the object belongs to. */
  public accountId: string;
  /** The location's unique ID the object belongs to. */
  public locationId: string;

  /**
   * The status of the visit.
   *
   * CHECKED_IN: Visitor is checked in.
   * CHECKED_OUT: Visitor is checked out.
   * DEACTIVATED: This record is deactivated.
   */
  public status = 'CHECKED_IN';

  /** The visitor's first name. */
  public firstname: string;
  /** The visitor's last name. */
  public lastname: string;

  /** Names of companions. */
  public companionNames: string[] = [];

  /** The visitor's physical address. */
  public address?: Address;
  /** The visitor's email address. */
  public email?: string;
  /** The visitor's phone number. */
  public phoneNumber?: string;

  /** The name of the table during the stay. */
  public dineInPlaceName?: string;
  /** The access requirement to be fulfilled to have access to restaurant. */
  public accessRequirement?: string;

  /** Approximate duration of stay, in minutes. */
  public durationOfStay = 180;
  /** The time after which this object is deleted, in days. */
  public durationOfStorage = 28;

  /** The timestamp indicating the start of the visit, in RFC 3339 format. */
  public checkedInAt: string;
  /** The timestamp indicating the end of the visit, in RFC 3339 format. */
  public checkedOutAt?: string;

  /** The time when the object was created, in RFC 3339 format. */
  public createdAt: string;

  /**
   * Last modification timestamp in RFC 3339 format, e.g., "2016-08-15T23:59:33.123Z" would indicate the UTC time (denoted by Z)
   * of August 15, 2016 at 23:59:33 and 123 milliseconds.
   */
  public updatedAt: string;
}
